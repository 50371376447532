import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/vercel/path0/src/providers/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogPageview","PHProvider"] */ "/vercel/path0/src/providers/providers.tsx");
